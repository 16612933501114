<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" title="Reservation" size="lg">
        <div class="text-center">
            <b-col lg="8" offset-lg="2">
                <label>Début</label>
                <date-time-picker v-model="form.dateDebut"/>

                <label class="mt-2">Fin</label>
                <date-time-picker v-model="form.dateFin"
                                  :min-datetime="form.dateDebut"
                                  ref="dateFinPicker"/>
            </b-col>
            <b-col class="text-left" lg="10" offset-lg="1">
                <hr/>
                <label>Commentaire utilisateur</label>
                <balloon-editor v-model="form.commentaireUtilisateur"/>
                <hr/>
            </b-col>

            <b-button @click="editReservation" variant="success">Valider</b-button>
            <b-button @click="hideModal" variant="secondary">Annuler</b-button>
        </div>
    </b-modal>
</template>

<script>
    const BalloonEditor  = () => import('@/components/BalloonEditor');
    const DateTimePicker = () => import('@/components/DateTimePicker');
    const UserName       = () => import('@/components/UserName');

    import demande    from '@/util/demande';
    import modalMixin from '@/mixin/modalMixin';
    import {apiPath}  from '@/util/http';

    export default {
        name: "EditReservationModal",
        components: {BalloonEditor, DateTimePicker, UserName},
        mixins: [modalMixin],
        props: {
            reservation: {
                type: Object,
                required: true
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            modalRef: 'displayReservationModal',
            form: {
                dateDebut: null,
                dateFin: null,
                commentaireUtilisateur: null
            }
        }),
        methods: {
            ...demande,
            editReservation() {
                this.axios
                    .post(apiPath('edit_reservation', {reservation: this.reservation.id}), this.form)
                    .then(() => {
                        this.$toaster.success('Reservation modifiée avec succès');
                        this.callback();
                        this.hideModal();
                    })
                    .catch(() => this.$toaster.error("Erreur lors de la modification de la reservation"));
            }
        },
        mounted() {
            this.form.commentaireUtilisateur = this.reservation.commentaireUtilisateur;
            this.form.dateDebut              = this.reservation.dateDebut.toISOString(true);
            this.form.dateFin                = this.reservation.dateFin.toISOString(true);
        }
    }
</script>

<style scoped>
    table {
        width: 100%;
    }

    td {
        padding-bottom: 0.5rem;
    }

    td.text-right {
        width: 33%;
    }

    td.text-left {
        padding-left: 10px;
    }
</style>